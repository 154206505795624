import React from "react"
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from "react-helmet"
import {Link} from 'gatsby';

export default function Recruiters() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Online testing for recruiters | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              For recruiters
            </h2>
            <p className="mt-6 text-lg font-normal text-gray-600 ">
            Skillmeter is the solution to assess the skills of your job candidates
          </p>            
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                    <p className="text-2xl font-bold text-gray-900 ">
                        Do you need an easy and secure solution to assess the skills of your job candidates?
                    </p>
                    <p className="text-lg font-normal text-gray-600 ">
                        Skillmeter is here for you. We built the best pre-employment screening platform to answer your needs. You can build your own tests within minutes and have your candidates take them from anywhere.
                    </p>
                    <p className="mt-8 text-2xl font-bold text-gray-900 ">
                        Cloud solution
                    </p>
                    <p className="text-lg font-normal text-gray-600 ">
                        With Skillmeter you don't need to worry about installing or upgrading software. Every new feature that we add will be automatically available to everyone.
                    </p>
                    <p className="mt-8 text-2xl font-bold text-gray-900 ">
                        Anywhere/anytime
                    </p>
                    <p className="text-lg font-normal text-gray-600 ">
                        All tests are available anytime and job candidates can take the exams from your company or from their home.
                    </p>
                    <p className="mt-8 text-2xl font-bold text-gray-900 ">
                        Flexible branding
                    </p>
                    <p className="text-lg font-normal text-gray-600 ">
                        Customize and brand your Skillmeter account to match the look & feel of your company.
                    </p>
                    <p className="mt-8 text-2xl font-bold text-gray-900 ">
                        Test builder
                    </p>
                    <p className="text-lg font-normal text-gray-600 ">
                        With Skillmeter you can create your own questionnaire or programming tests that can be public/private, timed, scored and with a wide variety of question types.
                    </p>

                    <p className="mt-8 text-2xl font-bold text-gray-900 ">
                        Friendly reporting
                    </p>
                    <p className="text-lg font-normal text-gray-600 ">
                        You get the results instantly by email and all results are archived in the system.
                    </p>

                    <p className="mt-8 text-2xl font-bold text-gray-900 ">
                        Secure
                    </p>
                    <p className="text-lg font-normal text-gray-600 ">
                        We know that your security & privacy are very important for you so we built a platform that you can trust.
                    </p>

                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
  
      {/* CTA */}
      <section class="py-4 bg-blue-50 sm:py-6 lg:py-8">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="text-center">
          <h2 class="mx-auto text-4xl font-bold text-gray-900 ">
            Ready to give us a try?  The first 14 days are free.
          </h2>


            <Link to={'/pricing'} class="mt-4 inline-flex items-center justify-center px-6 py-3 text-base font-bold bg-smcolor text-white rounded-xl focus:outline-none hover:bg-smcolor-dark  hover:text-white " role="button">
              See Plans &amp; Pricing
            </Link>

        </div>
      </div>
    </section>

        <Footer />
      </>
    )
}